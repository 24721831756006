import React, { useContext } from 'react';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import {types, areas} from '../../utils/data';

import SearchContext from '../../store/search-context';


const Search = (props) => {
    const searchCtx = useContext(SearchContext);
    let perfecturesOutput = [];
    let perfecturesInitial = [] 
    Object.entries(areas).map((area) => {
        area[1].map( (perfecture) => {
            perfecturesInitial.push(perfecture);
        })
    });
    if(searchCtx.searchData.area){
        perfecturesOutput = areas[searchCtx.searchData.area].map( (perfecture, key) => 
            <option key={key} value={perfecture.toString()}>{perfecture.toString()}</option>
        ) 
    }else{
        perfecturesOutput = perfecturesInitial.map( (perfecture, key) => 
            <option key={key} value={perfecture}>{perfecture}</option>
        ) 
    }

    return (
        <Card bg="dark" text="light" className="mb-3 mt-3">
            <Card.Body>
                    <Form onSubmit={(e) => props.handleSearch(searchCtx,e)}>
                        <Row >
                            <Form.Group as={Col} xs={12} md={4} controlId="formKeyword" className="mb-1 mt-1">
                            <Form.Control placeholder="キーワード" onChange={(e) => searchCtx.handleSearch(e,"keyword")} />
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={2} controlId="formArea" className="mb-1 mt-1">
                            <Form.Select defaultValue="" onChange={(e) => searchCtx.handleSearch(e,"area")}>
                                <option　value="">-- 地方 --</option>
                                { Object.keys(areas).map((area, key) => <option key={key} value={area}>{area}</option>) }
                            </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={2} controlId="formPerfecture"　className="mb-1 mt-1" onChange={(e) => searchCtx.handleSearch(e,"perfecture")}>
                                <Form.Select defaultValue="エリア...">
                                    <option value="">-- 都道府県 --</option>
                                    {perfecturesOutput}
                                </Form.Select>
                            </Form.Group>
                            
                            <Form.Group as={Col} xs={12} md={2} controlId="formType" className="mb-1 mt-1">
                                <Form.Select defaultValue=""　onChange={(e) => searchCtx.handleSearch(e,"type")}>
                                    <option value=""> -- 業種 -- </option>
                                    { types.map((type, key) => <option key={key} value={type}>{type}</option>) }
                                </Form.Select>
                            </Form.Group>

                            <Col xs={12} md={2} >
                                <div className="d-grid gap-2 mt-1">
                                    <Button 
                                        variant="primary" 
                                        type="button" 
                                        onClick={(e) => props.handleSearch(searchCtx,e)}>
                                        検索
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
            </Card.Body>
        </Card>
            );
};

export default Search;