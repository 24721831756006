import { createContext, useState } from 'react';

const AuthContext = createContext({
    isLogin: 0,
    isAdmin: false,
    token: "",
    expiration: "",
    uid: "",
    login: (token, expiration, role) => {},
    logout: () => {}
});

export function AuthContextProvider(props) {
    //const initialToken = localStorage.getItem('token');
    //const initialRole = localStorage.getItem('role');
    //const initialExpiration = localStorage.getItem('expiration');
    const [userToken, setUserToken] = useState("");
    //const [expirationTime, setExpirationTime] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    const isLogin = userToken ? 1 : 0;


    function logoutHandler(){
        setUserToken("");
        //setExpirationTime(null);
        setIsAdmin(false);

        //localStorage.removeItem('expiration');
        //localStorage.removeItem('token');
        //localStorage.removeItem('role');


    }

    function loginHandler(token, expiration, role){
        //expiration = Math.floor((Date.now() / 1000) + 3600);
        //setExpirationTime(expiration);
        setUserToken(token);
        setIsAdmin(role);

        //localStorage.setItem('expiration', expiration);
        //localStorage.setItem('token', token);
        //localStorage.setItem('role', role);
    }

    // function checkExpiration(){
        // const currentTime = Math.floor(Date.now() / 1000);
        // if(expirationTime < currentTime) logoutHandler();
    // }
    

    const context = {
        isLogin,
        isAdmin,
        token: userToken,
        login: loginHandler,
        logout: logoutHandler
    }


    return <AuthContext.Provider value={context}>
        {props.children}
    </AuthContext.Provider>
}

export default AuthContext;