import React from 'react';
import { useFormContext } from "react-hook-form";

import styles from "./ItemForm.module.css"
import {types, areas} from '../../../utils/data';


import { Button, Alert } from 'react-bootstrap';
import { TiTick } from "react-icons/ti";

import ItemTextarea from './ItemTextarea/ItemTextarea';


const ItemForm = (props) => {
    const {item: {name,area,perfecture, city, type}} = props
    const  { register, formState: { errors }} = useFormContext(); // retrieve all hook methods

    let perfecturesOutput = "";
    if(area){
        perfecturesOutput = areas[area].map( (perfecture, key) => 
            <option key={key} value={perfecture}>{perfecture}</option>
        ) 
    }

    return (
        <React.Fragment>
            <div className={ !errors.name ? styles.formGroup : [styles.formGroup, styles.error].join(' ') }>
                            <label htmlFor="name">店舗</label>
                            <div>
                                {/* Name */}
                                <input 
                                    id="name"
                                    aria-invalid={errors.name ? "true" : "false"}
                                    placeholder="店舗"
                                    {...register("name", { 
                                    required: true 
                                    })} 
                                    defaultValue={name}
                                    />
                                {errors.name && errors.name.type === "required" && <span role="alert" className={styles.errorMessage}>店舗名を入力してください</span>}
                            </div>
                        </div>

                        {/* Area */}
                        <div className={ !errors.area ? styles.formGroup : [styles.formGroup, styles.error].join(' ') } >
                            <label htmlFor="area">地域</label>
                            <select 
                                id="area" 
                                {...register("area", { 
                                    required: true 
                                })}
                                onChange={props.selectHandler}
                                defaultValue={area}
                                >
                            <option disabled value="0"> -- 地域を選びください -- </option>
                            { Object.keys(areas).map((area, key) => <option key={key} value={area}>{area}</option>) }
                            </select>
                        </div>

                        {/* Perfecture */}
                        <div className={ !errors.perfecture ? styles.formGroup : [styles.formGroup, styles.error].join(' ') }>
                            <label htmlFor="perfectures">都道府県</label>
                            <select 
                                id="perfectures" 
                                {...register("perfecture", { 
                                    required: true 
                                })}
                                defaultValue={perfecture}
                            >
                            <option disabled value="0"> -- 都道府県を選びください -- </option>
                            { perfecturesOutput }
                            </select>
                        </div>

                        {/* city (区) */}
                        <div className={ !errors.city ? styles.formGroup : [styles.formGroup, styles.error].join(' ') }>
                            <label htmlFor="city">市区町村</label>
                            <div>
                            <input 
                                id="city"
                                aria-invalid={errors.city ? "true" : "false"}
                                placeholder="市区町村"
                                {...register("city")} 
                                defaultValue={city}
                                />
                            </div>
                        </div>
                        
                        {/* Type */}
                        <div className={ !errors.type ? styles.formGroup : [styles.formGroup, styles.error].join(' ') }>
                            <label htmlFor="type">業種</label>
                            <select id="type" {...register("type", { 
                                required: true 
                                })}
                                defaultValue={type}
                            >
                            <option disabled value="0"> -- 業種を選びください -- </option>
                            { types.map((type, key) => <option key={key} value={type}>{type}</option>) }
                            </select>
                        </div>
                        

                        {/* Textarea Content */}
                        <ItemTextarea 
                            label="内容"　
                            name="content" 
                            fromItem={props.fromItem} 
                            onChange={props.onChangeContentGandler}/>

                        {/* Textarea Contract */}
                        <ItemTextarea 
                            label="契約情報"　
                            name="contract" 
                            fromItem={props.fromItem} 
                            onChange={props.onChangeContractGandler}/>
                        

                        <div className="d-grid gap-2">
                                <Button size="lg" className="mt-2" type="submit" >{props.submitButton}</Button>
                                { props.feedback 
                                ? <Alert  variant="info" className="fixed-top m-3">
                                    <TiTick /> 更新しました！
                                </Alert> : null }
                            </div>
        </React.Fragment>
    );
};

export default ItemForm;