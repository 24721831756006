import React, {useEffect, useState, useContext} from 'react';
import { useForm, FormProvider } from "react-hook-form";
import AuthContext from '../../store/auth-context';
import "firebase/auth";
import axios from "axios";
import { useLocation, useHistory, Link } from 'react-router-dom';

import { Button, Form, Card, Spinner, Modal } from 'react-bootstrap';
import { TiDelete, TiArrowLeft } from "react-icons/ti";

import ItemForm from './ItemForm/ItemForm';
import ItemProfile from './ItemProfile/ItemProfile';

import styles from "./Item.module.css";


const Item = () => {
    const authCtx = useContext(AuthContext);
    const [item, setItem] = useState({ 
        name: "",
        area: "",
        perfecture: "0",
        city: "",
        type: "0",
        content: "",
        contract: ""
    });
    const methods = useForm();
    const { handleSubmit, setValue } = methods;
    const[isLoading, setIsLoading] = useState(false); 
    const[feedback, setFeedback] = useState(false); 
    const [deleteModalShow, setDeleteModalShow] = useState(false);


    
    //Get state props from react router link component
    const location = useLocation();
    let history = useHistory();
    let fromItem = null;
    let buttonDelete = null;

    
    
    if(location.state){
        fromItem = location.state ? location.state.id : null;
        buttonDelete = (<Button  variant="outline-danger" className={styles.floatRight} size="sm" type="button"  onClick={() => setDeleteModalShow(true)}><TiDelete /> 削除</Button>)
    }else{ 
        fromItem = null;
    }
    useEffect(() => {
            setIsLoading(true);
            
            if(fromItem){
                axios.get('https://scout-76f18-default-rtdb.asia-southeast1.firebasedatabase.app/shops/'+fromItem+".json?auth="+authCtx.token)
                .then(res => {
                    if(res.data){
                        setItem(res.data);
                        if(authCtx.isAdmin){
                            setValue("name", res.data.name);
                            setValue("area", res.data.area);
                            setValue("perfecture", res.data.perfecture);
                            setValue("city", res.data.city);
                            setValue("type", res.data.type);
                            setValue("content", res.data.content);
                            setValue("contract", res.data.contract);
                        }
                    }else{
                        console.log("error getting data from server");
                        
                    }
                });
            }else{
                //when changing from item to new item
                if(!(authCtx.isAdmin === true)){
                    history.push({ pathname: "/admin" })
                }else{
                    setValue("name", "");
                    setValue("area", "0");
                    setValue("perfecture", "0");
                    setValue("city", "");
                    setValue("type", "0");
                    setValue("content", "");
                    setValue("contract", "");
                }
            }
            setIsLoading(false);
    }, [fromItem, setValue, authCtx.isAdmin, history]); 


    const onSubmit = data => {
        setIsLoading(true);
        data.filter = false;
        if(!fromItem){
            //New Item
            axios.post('https://scout-76f18-default-rtdb.asia-southeast1.firebasedatabase.app/shops.json?auth='+authCtx.token, data)
            .then(res => {
              if(res.data){
                history.push({
                    pathname: "/admin",
                    state: { detail: "新規作を成完成しました"}
                })
              }else{
                //Error feedback
                console.log("Created new error");
              }
              setIsLoading(false);

            })
        }else{
            //Update Item
            axios.put('https://scout-76f18-default-rtdb.asia-southeast1.firebasedatabase.app/shops/'+fromItem+'.json?auth='+authCtx.token, data)
            .then(res => {
              if(res.data){
                //Succeed feedback!
                setIsLoading(false);
                feedbackHandler();
              }else{
                //Error feedback
                console.log("Update error");
                setIsLoading(false);
              }
            })
        }
      };

      const onChangeContentHandler = ({target: {value}}) => {
        setItem(prevState => ({
            ...prevState,
            content: value
        }));
      };
      const onChangeContractHandler = ({target: {value}}) => {
        setItem(prevState => ({
            ...prevState,
            contract: value
        }));
      };
      const selectHandler =  event  => {
        setItem(prevState => ({
            ...prevState,
            area: event.target.value
        }));
      }
      const feedbackHandler = () => {
        setFeedback(true);
        setTimeout(() => 
            setFeedback(false), 
        1000);
      }

      const deleteHandler = (id) => {
        axios.delete('https://scout-76f18-default-rtdb.asia-southeast1.firebasedatabase.app/shops/'+id+".json?auth="+authCtx.token)
        .then( res =>
             history.push({
                pathname: "/admin",
                state: { detail: "店舗は削除しました"}
            })
        )
      }

      
   
      let submitButton = fromItem ? "更新" : "新規作成";
      if(isLoading){
          submitButton =  <Spinner animation="border" variant="light" size="sm" />
      }else{
        submitButton = fromItem ? "更新" : "新規作成";
      }
      

    return (
        <React.Fragment>
            <Card className="mt-3 mb-3">
                <Card.Body>
                <h2><span>店舗情報</span> {authCtx.isAdmin === true ? buttonDelete : null }</h2>
                <hr />
                    {authCtx.isAdmin === true ? 
                    <FormProvider {...methods} >
                        <Form onSubmit={handleSubmit(onSubmit)} >
                            <ItemForm 
                                item={item}
                                selectHandler={(e) => selectHandler(e)} 
                                fromItem={fromItem} 
                                onChangeContentHandler={(e) => onChangeContentHandler(e)}
                                onChangeContractHandler={(e) => onChangeContractHandler(e)}
                                feedback={feedback} 
                                submitButton={submitButton} />
                        </Form>
                    </FormProvider> : 
                    <ItemProfile item={item}/> }
                </Card.Body>
            </Card>
            {authCtx.isAdmin ? 
            <Modal
                size="sm"
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                aria-labelledby="deleteModal" 
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="deleteModal">
                        削除確認
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex">
                    <Button variant="secondary" className="d-block w-50" onClick={() => setDeleteModalShow(false)}>キャンセル</Button>
                    <Button variant="danger" className="d-block w-50" onClick={() => deleteHandler(fromItem)}>削除</Button>
                </Modal.Body>
            </Modal> : null }
            <Button variant="outline-primary" className="mb-3" as={Link} to="/admin"  size="lg"><TiArrowLeft style={{fontSize: "1.7rem"}}/> 戻る</Button>

        </React.Fragment>
    );
};

export default Item;
