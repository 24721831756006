import React, {useContext} from 'react';
import SearchContext from '../../store/search-context';
import {Accordion, Button } from 'react-bootstrap';
import style from './Filter.module.css';

const Filter = () => {
    const searchCtx = useContext(SearchContext);
    let cityList = Object.entries(searchCtx.shoplist).map(shop => shop[1].city );
    const handleFilters  = (item) => {
        let index = searchCtx.filters.findIndex(i => i === item)
        if(index === -1){
            //Add
            searchCtx.setShoplist([...searchCtx.filters,item], null, "setFilters" )
        }else{
            //Remove
            let filtered = searchCtx.filters.filter((i) => i.indexOf(item) === -1);
            searchCtx.setShoplist(filtered, null, "setFilters" )
        }
        //Call searchFilter reducer
        //searchCtx.setShoplist(filters, null, "setFilters" )
    }
    
    //Delete repetitions
    let filterList = [...new Set(cityList)];
    let checkVariant = false;
    const filterListOutput = filterList.map( (item, key) => {
        //Also check if exist in filters state to toggle active
        if(searchCtx.filters.indexOf(item) >= 0){
           checkVariant = true;
        }else{
            checkVariant = false;
        };
        if(item !== ""){
            return <li key={key} className="d-inline m-1">
                <Button variant={ !checkVariant ? "outline-primary" : "primary" } className={!checkVariant ? style.CustomStyle : null} size="sm" onClick={()=>handleFilters(item)}>{ item }</Button>
            </li>
        }
    }); 

    return (
        <Accordion　className="mb-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header >市区町村</Accordion.Header>
                <Accordion.Body>
                    <ul className="d-flex flex-wrap m-0 p-0">
                        {filterListOutput}
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>        
    );
};

export default Filter;