import {createContext, useReducer} from "react";

const SearchContext = createContext({
  searchData: {
    name: "",
    content: "",
    area: "",
    perfecture: "",
    city: "",
    type: "",
  }, 
  shoplist: {
    filters: [],
    shoplist: {}
  },
  handleSearch: (e, type) => {},
  setShoplist: (list, search, type) => {}
});



const searchReducer = (state, action) =>{
  switch(action.type){
    case 'keyword':
      return {...state, name: action.value};
    case 'area':
      return {...state, area: action.value, perfecture: ""};
    case 'perfecture':
      return {...state, perfecture: action.value};
    case 'type':
      return {...state, type: action.value};
    default:
      return {...state};
  }
}

const shoplistReducer = (state, action) => {
  let filteredShoplist =[]; 
  switch(action.type){
    case 'search':
    //Replace japanese whitespaces
    const keywords = action.search.searchData.name.replace("　", " ").split(" ");
      //Search by keyword ------
      if(action.search.searchData.name !== ""){
          //Find if any element inside array of keywords exist inside of a string
          action.list = action.list.filter( (shop) => 
            (keywords.some( keyword => shop[1].name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)  
            || keywords.some( keyword => shop[1].content.toLowerCase().indexOf(keyword.toLowerCase()) !== -1))
          );
      }

      //Search by area ------
      if(action.search.searchData.area !== 0){
        action.list = action.list.filter( (shop) => 
          shop[1].area.indexOf(action.search.searchData.area) !== -1 
        );
        //Search by perfecture
        if(action.search.searchData.perfecture !== 0){
          action.list = action.list.filter( (shop) => 
            shop[1].perfecture.indexOf(action.search.searchData.perfecture) !== -1 
          );
        }
      }

      //Search by type ------
      if(action.search.searchData.type !== 0){
        action.list = action.list.filter( (shop) => 
          shop[1].type.indexOf(action.search.searchData.type) !== -1 
        );
      } 

      //Convert data to object fromEntries and change State
      action.list = Object.fromEntries(action.list);
     
      return  {filters: [], shoplist: action.list};


    case 'setFilters':
        filteredShoplist = Object.entries(state.shoplist);
        filteredShoplist.map((shop,index) => {
          if(action.list.indexOf(shop[1].city) !== -1){
            filteredShoplist[index][1].filter = true;
          }else{
            filteredShoplist[index][1].filter = false;
          }
        });
        filteredShoplist = Object.fromEntries(filteredShoplist);

        return {filters: action.list, shoplist: filteredShoplist};


    case 'load':
      return {...state, shoplist: action.list};
    

    default:
      return {...state};
  }
  
}


export function SearchContextProvider(props) {
  const [searchState, dispatchSearch] = useReducer(searchReducer, {
    name: "",
    content: "",
    area: "",
    perfecture: "",
    city: "",
    type: "",
    filter: 0
  });
  const [shoplistState, dispatchShoplist] = useReducer(shoplistReducer, { 
    filters: [], 
    shoplist: {}
  });

  const handleSearch = (event, type) => {
    dispatchSearch({type: type, value: event.target.value});
  }

  const handleShoplist = (list, search, type) => {
    //Type for switch action, search for search values, list for shoplist we want to handle
    dispatchShoplist({ list: list, search: search, type: type});
  }


  const context = {
    shoplist: shoplistState.shoplist,
    filters: shoplistState.filters,
    searchData: searchState,
    handleSearch,
    setShoplist: handleShoplist
  }

    
  return <SearchContext.Provider value={context}>
      {props.children}
  </SearchContext.Provider>
}
export default SearchContext;