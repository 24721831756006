import React,  { useContext } from 'react';
import ShopListItem from "./ShopListItem/ShopListItem";
import { ListGroup } from 'react-bootstrap';
import SearchContext from '../../store/search-context';

const Shoplist = (props) => {
    const searchCtx = useContext(SearchContext);
    
    let listItems = Object.keys(searchCtx.shoplist).map( (key, index) => (
        <ShopListItem 
            key={index} 
            id={key}
            uri={index}
            title={searchCtx.shoplist[key].name} 
            area={searchCtx.shoplist[key].area}
            perfecture={searchCtx.shoplist[key].perfecture}
            city={searchCtx.shoplist[key].city}
            type={searchCtx.shoplist[key].type} 
            filter={searchCtx.shoplist[key].filter}
            />
    ));
    if (!listItems.length) listItems= (<p className="text-center mt-5 text-secondary">検索結果はありませんでした (● ˃̶͈̀ロ˂̶͈́)੭ꠥ⁾⁾</p>);
    return (
        <ListGroup className="mb-5">
            { listItems }
        </ListGroup>
    );
};

export default Shoplist;