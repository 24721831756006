import React, {useContext} from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { TiPlus } from "react-icons/ti";
import { Link } from "react-router-dom";

import AuthContext from "../../store/auth-context";

  
const Header = () => {
    const authCtx = useContext(AuthContext);
    return (
            <Navbar bg="primary" variant="dark" >
                <Container>
                    <Navbar.Brand>WEB求人</Navbar.Brand>
                    <Nav className="me-auto">
                        { authCtx.isLogin ? <Nav.Link as={Link} to="/admin">検索</Nav.Link> : null }
                        { authCtx.isLogin && (authCtx.isAdmin === true) ?  <Nav.Link as={Link}to="/item"><TiPlus /> 新規作成</Nav.Link> : null }
                    </Nav>
                    <Nav className="justify-content-end">
                        { authCtx.isLogin ? <Nav.Link as={Link}to="/" onClick={() => authCtx.logout()}>ログアウト</Nav.Link> : null }    
                    </Nav>
                </Container>
            </Navbar>
    );
};

export default Header;