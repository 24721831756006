import React, { useContext, useEffect } from 'react';
import AuthContext from '../../store/auth-context';
import axios from "axios";

import { useForm } from "react-hook-form";
import useAxios from 'axios-hooks';
import { Card, Form, Button, FloatingLabel, Spinner, Alert, Row, Col} from 'react-bootstrap';
import styles from './Login.module.css';



const Login = () => {
    const { register, handleSubmit, formState: { errors} } = useForm();
    const authCtx = useContext(AuthContext);
    
    //Login axios hook
    const [{ data: loginData, loading: loginIsLoading,  error: loginIsError },
        loginFetch
    ] = useAxios({
            url: 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyBIKelXHkIydEbCUb90lS06UshNYUVMg5w',
            method: 'POST'
        },
        { manual: true }
    );

    

    const onSubmit = (data, e) => {
        //alert(JSON.stringify(data))
        const formData = {
            data: {
                email: data.email,
                password: data.password,
                returnSecureToken: true
              }
        };
            loginFetch(formData);
    };

    useEffect(() => {
        if(loginData) { 
            axios.get('https://scout-76f18-default-rtdb.asia-southeast1.firebasedatabase.app/users.json')
            .then(res => {
                const expirationTime = new Date( new Date().getTime() + (+loginData.expiresIn * 1000) );
                const permission = res.data.admin === loginData.email;
                authCtx.login(loginData.idToken, expirationTime.toISOString(), permission); 
            }) 

            
        }
    }, [loginData, authCtx]);

    let loginButton = 'ログイン';
    if(loginIsLoading){
        loginButton = <Spinner animation="border" variant="light" size="sm" />;
    }
    

    return (
        <Row>
            <Col className="justify-content-center d-flex">
                <Card className={styles.CustomCard} >
                    <Card.Body>
                        <h1 className="text-center mb-2">ログイン</h1>
                        <Form onSubmit={handleSubmit(onSubmit)} >
                                <FloatingLabel  controlId="email"  label="メール"  className="mb-2" style={{"width": "100%"}}>
                                    <Form.Control 
                                        type="email" 
                                        placeholder="メールを入力してください" 
                                        name="email"
                                        isInvalid={errors.email}
                                        {...register("email", {
                                            required: "メールを入力してください",
                                            pattern: {
                                                value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                                                message: "正しいメールを入力してください　例）name@mail.com"
                                            }
                                        })}/>
                                        {errors.email && (
                                    <Form.Control.Feedback type="invalid">
                                    {errors.email?.message}
                                    </Form.Control.Feedback>
                                )}
                                </FloatingLabel>
                        
                                <FloatingLabel  controlId="password" label="パスワード"  style={{"width": "100%"}}>
                                    <Form.Control 
                                        type="password" 
                                        placeholder="パスワード" 
                                        aria-invalid={errors.password ? "true" : "false"}
                                        isInvalid={errors.password}
                                        name="password"
                                        {...register("password", {
                                            required: "パスワードを入力してください"
                                        })}/>

                                {errors.password && (
                                    <Form.Control.Feedback type="invalid">
                                    {errors.password.message}
                                    </Form.Control.Feedback>
                                )}
                                </FloatingLabel>
                            
                            <div className="d-grid gap-2">
                                <Button type="submit" size="lg"　className="mt-2">{loginButton}</Button>
                            </div>
                        </Form>
                    {loginIsError ? <Alert variant="danger" className="mt-3">
                            メールまたはパスワードは正しくではありません。
                        </Alert> : null}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        
    );
};

export default Login;