import React, {useContext, useCallback} from 'react';
import { Link } from "react-router-dom";
import { Badge, ListGroup } from 'react-bootstrap';
import { TiLocation, TiDocumentText } from "react-icons/ti";
import SearchContext from '../../../store/search-context';
import styles from "./ShopListItem.module.css";

const ShopListItem = (props) => {
    const searchCtx = useContext(SearchContext);
    let locationBadge = "";
    if(props.city){
        locationBadge = <Badge bg="light" text="dark"><TiLocation /> {props.area+" - "+props.perfecture+" - "+props.city}</Badge>
    }else{
        locationBadge = <Badge bg="light" text="dark"><TiLocation /> {props.area+" - "+props.perfecture}</Badge>
    }

    const clearFilters = useCallback(() => {
        searchCtx.setShoplist([], null, "setFilters" );
    },[searchCtx])

    return (
        <React.Fragment>
            { props.filter || !searchCtx.filters.length ? <ListGroup.Item className={styles.ShopListItem}>
                <Link 
                    onClick={clearFilters}
                    to={{
                        pathname:'/item/' + props.uri,
                        state: {
                            id: props.id,
                        },
                }}>
                    <h2>{props.title}</h2>
                    <p>{locationBadge} <Badge bg="light" text="dark"><TiDocumentText /> {props.type}</Badge></p>
            </Link>
            </ListGroup.Item> : null}
        </React.Fragment>
    );
};

export default ShopListItem;