import React, {useState} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ListGroup, Button } from 'react-bootstrap';
import { TiBusinessCard, TiLocationOutline, TiMessage, TiThList, TiClipboard } from 'react-icons/ti';

const ItemProfile = (props) => {
    const {item: {name,area,perfecture, city, type, content}} = props
    const [isCopied, setIsCopied] = useState(false);

    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
      };

    return (
        <React.Fragment>
            <ListGroup variant="flush">
                <ListGroup.Item><strong className="d-block text-primary"><TiBusinessCard  /> 店舗</strong> <h2 className="d-block">{name}</h2></ListGroup.Item>
                <ListGroup.Item><strong className="d-block text-primary"><TiLocationOutline /> 位置</strong><p>  {area} - {perfecture} - {city} </p></ListGroup.Item>
                <ListGroup.Item><strong className="d-block text-primary"><TiThList /> 業種</strong><p>  {type}</p></ListGroup.Item>
                <ListGroup.Item>
                    <strong className="d-block text-primary"><TiMessage /> 内容
                    
                    </strong>
                    <p style={{whiteSpace: "pre-line"}}> {content} </p>
                    </ListGroup.Item>
            </ListGroup>
            <CopyToClipboard text={content} onCopy={onCopyText}>
                            <div className="mb-1 ">
                                <Button  variant="outline-info " size="sm" type="button" className="mb-2"><TiClipboard />コピー</Button>
                                {isCopied && <span  className="text-info" >
                                    コピーしました!
                                </span>}
                            </div>
                        </CopyToClipboard>
        </React.Fragment>
    );
};

export default ItemProfile;