import React, {useEffect, useContext, useState, useCallback} from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import SearchContext from '../../store/search-context';
import AuthContext from '../../store/auth-context';

import Filter from '../../components/Filter/Filter';
import Shoplist from '../../components/Shoplist/Shoplist';
import Search from '../../components/Search/Search';

import { Spinner, Alert } from 'react-bootstrap';
import { TiTick } from "react-icons/ti";

const Admin = () => {
    const searchCtx = useContext(SearchContext);
    const authCtx = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const[isDelete, setIsDelete] = useState(false); 
    let feedbackMessage="";

    if(location.state) {
      feedbackMessage = location.state.detail;
    }
   

    useEffect(() => {
        setIsLoading(true);
        axios.get('https://scout-76f18-default-rtdb.asia-southeast1.firebasedatabase.app/shops.json?auth='+authCtx.token)
        .then(res => {
          if(res.data){
            //Add　filtering property
            searchCtx.setShoplist(res.data, null, "load");
          }else{
            console.log("Request failed");
          }
          setIsLoading(false);
        }) 
    }, []);




  const handleSearch = useCallback((searchContext, event) => {
    event.preventDefault();
    setIsLoading(true);
    axios.get('https://scout-76f18-default-rtdb.asia-southeast1.firebasedatabase.app/shops.json?auth='+authCtx.token)
      .then(res => {
        if(res.data){
          let filteredShopList = Object.entries(res.data);
          searchCtx.setShoplist(filteredShopList, searchContext, "search" );
        }else{
          console.log("Search Request failed");
        }
        setIsLoading(false);
      }) 
  })



    return (
      <React.Fragment>
            <Search handleSearch={handleSearch}/>
            {isLoading 
            ? (<div className="text-center mt-4"><Spinner animation="border" variant="primary" /></div>) 
            : (<div><Filter /><Shoplist shoplist={searchCtx.shoplist} /></div>) }
           { isDelete 
            ? <Alert  variant="info" className="fixed-bottom m-3 border-radius-0">
                <TiTick /> {feedbackMessage}
              </Alert> : null }
      </React.Fragment>
    )
}

export default Admin;