import React, {useContext, useEffect} from "react";
import { SearchContextProvider } from './store/search-context';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "react-scroll-to-top";

import AuthContext from "./store/auth-context";

import Login from "./containers/Login/Login";
import Admin from "./containers/Admin/Admin";
import Item from "./containers/Item/Item";
import Header from "./components/Header/Header";

function App() {
  const authCtx = useContext(AuthContext);


  return (
    <SearchContextProvider>
      <Router>
          <Header />
          <Container>
            <Row >
              <Col >
                <Switch>
                  <Route path="/admin">
                    {authCtx.isLogin 
                      ? <Admin />
                      : <Redirect to="/" /> }
                  </Route>
                  <Route path="/item" exact>
                    {authCtx.isLogin 
                      ? <Item />
                      : <Redirect to="/" /> }
                  </Route>
                  <Route path="/item/:itemId" exact>
                    {authCtx.isLogin 
                      ? <Item />
                      : <Redirect to="/" /> }
                  </Route>
                  <Route path="/">
                    {!authCtx.isLogin 
                      ? <Login />
                      : <Redirect to="/admin" /> }
                  </Route>
                </Switch>
              </Col>
            </Row>
          </Container>
      </Router>
      <ScrollToTop smooth />
    </SearchContextProvider>
  );
}

export default App;
