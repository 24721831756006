import React, {useState} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useFormContext } from "react-hook-form";
import { Button } from 'react-bootstrap';
import styles from "../ItemForm.module.css"

import { TiClipboard } from "react-icons/ti";

const ItemTextarea = (props) => {
    const  { register} = useFormContext(); // retrieve all hook methods
    const [isCopied, setIsCopied] = useState(false);

    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
      };

    return (
        <div className={styles.formGroup}>
            <div className={styles.textAreaGroup}>
                <label htmlFor={props.name}>{props.label}</label>
                {props.fromItem && <CopyToClipboard text={props.name} onCopy={onCopyText}>
                    <div className="mb-1 ">
                    <Button  variant="outline-info" size="sm" type="button" className="mb-2"><TiClipboard />コピー</Button>
                    {isCopied && <p  className="text-info" >
                        コピーしました!
                    </p>}
                    </div>
                </CopyToClipboard> }
            </div>
            <textarea 
                id={props.name} 
                {...register(props.name)} 
                defaultValue={props.fromItem ? props.name : "" } 
                onChange={props.onChange}
                style={{whiteSpace: "pre", wordWrap: "break-word"}}
                multiline={true}
                contenteditable={true}></textarea>
        </div>
    );
};

export default ItemTextarea;